@import "./components/Hero/hero.scss";
@import "./components/Projects/projects.scss";
@import "./components/TimeLine/time-line.scss";

.kc_fab_main_btn {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #51565f;
  border: none;
  outline: none;
  color: #fff;
  font-size: 36px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
  transform: rotate(180deg);
}

.kc_fab_main_btn:active {
  background-color: #fff;
}
