.single-card {
  transition: 0.3s;
}

.single-card:hover {
  background-color: #e7e7e74f;
}

.spacer {
  padding: 1rem;
}
